/**
 * Custom style for the UGS website
 * This stylesheet is called after the other stylesheets
 * so should have precedence, other specificity notwithstanding
 */

/* Remove default browser styling */

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    /*display: none;*/
    -webkit-appearance: none;
}

/* Fix awesomplete styling */

.awesomplete > ul {
    position: initial;
    width: initial;
}

.awesomplete mark {
    padding: 0;
}

/* Undo sometimes terrible V13 styling decisions */

.form-control, .form-control:focus {
    background-color: #fff;
    border: 1px solid #ced4da;
}

.frappe-control:not([data-fieldtype='MultiSelectPills']):not([data-fieldtype='Table MultiSelect']).has-error input {
    border: 1px solid var(--red-400);
}

.main-column .page-content-wrapper {
    margin: 1rem 0;
}

.sidebar-item a.active {
    color: unset;
    font-weight: 600;
    background-color: #cfe5fc;
}

/* General styling */

.web-footer {
    background: #404042;
}

li a, a.badge-pill {
    color: #36414c;
}

/*a:hover,
a:focus,
a:active {
    text-decoration: underline;
    color: #161b1f;
}*/

.page-content-wrapper {
    width: 100%;
}

.btn-light {
    background-color: #eff2f5;
    border-color: #eff2f5;
}

.ugs-btn, .btn.ugs-btn {
    background-color: #ebecf1;
}

.ugs-btn:hover {
    background-color: #cccccc;
}

.input-group-append > .ugs-btn {
    border: 1px solid #ced4da;
}

.thin-short-hr {
    margin: 0 auto 0.5rem 0;
    max-width: 50rem;
    border-top-color: #d1d8dd;
}

.nobr {
    white-space: nowrap;
}

/**
 * V14 styling overrides
 */

p {
    line-height: unset;
}

.page-header {
    width: 100%;
}

.page-header h3 {
    margin-top: 0;
}

ol.breadcrumb {
    padding-top: 0;
    padding-bottom: 0;
}

/**
 * Generic styling for search boxes
 */

.ugs-search-form {
    position: relative;
    max-width: 25rem;
    height: 100%;
}

.ugs-search-input {
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size: 1rem;
}

.ugs-search-icon {
    position: absolute;
    right: 0;
    top: 48%;
    transform: perspective(1px) translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    cursor: pointer;
}

.ugs-card-border {
    border: 1px solid rgba(0, 0, 0, .125);
}

/**
 * Custom styling for the top nav bar
 */

body {
    height: unset;
    min-height: 100%;
}

.navbar {
    background: linear-gradient(to bottom, #ffffff, #f5f5f5);
    border-style: solid;
    border-radius: 0;
    border-width: 0 0 0.0625rem 0;
    border-color: #a9a9a9;
    padding: 0.5rem 1rem;
}

.navbar-brand img {
    margin: 0.25rem 0;
    max-height: 2.5rem;
    height: auto;
}

.navbar-nav {
    align-items: center;
}

.navbar-contact {
    margin: 0 1rem 0 0;
    font-size: 0.875rem;
}

.navbar-contact p {
    margin-bottom: 0;
    white-space: nowrap;
    line-height: 1.4;
}

.navbar-collapse {
    flex-grow: 0;
}

@media (max-width: 767.98px) {
    .ugs-nav-extra {
        width: 100%;
    }
}

.nav-item {
    text-align: center;
}

.nav-link {
    padding-top: 0;
    padding-bottom: 0
}


/**
 * -- Search bar styling
 */

.ugs-nav-search {
    position: relative;
    flex-grow: 1;
    max-width: 25rem;
}

.ugs-nav-search > input {
    padding-right: 1.875rem;
}

/**
 * Styles for the sidebar(s)
 */

/* Fixed width sidebar(s) above 616px */
.ugs-sidebar {
    flex: 0 0 200px;
}

.ugs-main-row {
    flex-wrap: nowrap;
}

/* Full width on small screens */
@media (max-width: 615px) {
    .ugs-sidebar {
        flex: none;
        width: 100%;
        font-size: 16px;
        border-left: none;
        border-right: none;
        border-top: 1px solid #d1d8dd;
    }

    /* Add a left border for symmetry on narrow screens */
    .ugs-sidebar-block-grid {
        border-left: 0.0625rem solid #404042;
    }

    .ugs-main-row {
        flex-wrap: wrap;
    }
}

.ugs-sidebar-collapse, .ugs-sidebar-collapse-entries {
    width: 100%;
}

.ugs-sidebar-block-grid > .ugs-sidebar-collapse > .ugs-sidebar-collapse-entries {
    /* Flexbox system for wrapping rows on 400px-615px screens */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: #003300;
}

.ugs-sidebar-row {
    /* Normally the blocks fill the sidebar */
    width: 100%;
}

@media (max-width: 615px) and (min-width: 400px) {
    /* For the 400px to 615px width, double up the first sidebar row */
    .sbar-row-1 {
        width: 50%;
    }
}

/**
 * -- Styling of the category links
 */

.ugs-sidebar-row {
    display: table;
    border-bottom: 0.0625rem solid #404042;
    border-right: 0.0625rem solid #404042;
    line-height: 1.2rem;
    font-weight: 500;
}

.ugs-sidebar-collapse {
    border-bottom: 0.125rem solid black;
}

.ugs-sidebar-row > a {
    display: table-cell;
    vertical-align: middle;
    color: #ffffff;
    text-decoration: none;
}

.ugs-sidebar-item {
    text-align: left;
    width: 100%;
    height: 3em;
    padding: 0.0625rem 0.25rem;
}

.ugs-sidebar-chevron {
    text-align: center;
    min-width: 3em;
    border-left: 0.0625rem solid #404042;
}

.ugs-sidebar-item.active {
    font-weight: bolder;
}

/**
 * -- Colouring and styling for the sidebar rows
 * Includes an 'active'/hover lighter version
 * The chevron boxes are also set to the higher level when they are
 * expanded
 */

.sbar-item-0 {
    background-color: #b1c75a;
    font-size: 1rem;
}

.sbar-item-1, .sbar-item-2, .sbar-item-3, .sbar-item-4, .ugs-sidebar-chevron {
    font-size: 0.875rem;
}

.sbar-item-0:hover,
.sbar-item-0.active {
    background-color: #c3d480;
}

.sbar-item-1,
.sbar-item-0[aria-expanded=true] {
    background-color: #6c6c6c;
}

.sbar-item-1:hover,
.sbar-item-1.active,
.sbar-item-0[aria-expanded=true]:hover {
    background-color: #868686;
}

.sbar-item-2,
.sbar-item-1[aria-expanded=true] {
    background-color: #b1c75a;
}

.sbar-item-2:hover,
.sbar-item-2.active,
.sbar-item-1[aria-expanded=true]:hover {
    background-color: #c3d480;
}

.sbar-item-3,
.sbar-item-2[aria-expanded=true] {
    background-color: #cc0033;
}

.sbar-item-3:hover,
.sbar-item-3.active,
.sbar-item-2[aria-expanded=true]:hover {
    background-color: #ff3366;
}

.sbar-item-4,
.sbar-item-3[aria-expanded=true] {
    background-color: #003333;
}

.sbar-item-4:hover,
.sbar-item-4.active,
.sbar-item-3[aria-expanded=true]:hover {
    background-color: #336666;
}

.sbar-dd {
    font-size: 1rem;
    transition: transform 0.3s ease-in-out;
}

a[aria-expanded=true] .sbar-dd {
    transform: scale(1, -1);
}

/**
 * -- Styling of the filter sidebar on Item Group
 */

.ugs-filter-sidebar {
    flex: 1 0 200px;
    max-width: 350px;
    margin-left: 0.5rem;
    margin-top: calc(24px + 0.625rem);
    padding: 0.5rem;
    border: 0.0625rem solid #d1d8dd;
    border-radius: 0.25rem;
}

.item-group-content {
    flex: 200 0;
}

.ugs-item-group-row {
    display: flex;
    flex-wrap: nowrap;
}

/* Full width on small screens */
@media (max-width: 768px) {
    .ugs-filter-sidebar {
        flex: none;
        max-width: unset;
        order: -1;
        width: 100%;
        font-size: 16px;
        margin-left: 0;
        margin-top: 0.5rem;
    }

    .ugs-item-group-row {
        flex-wrap: wrap;
        width: calc(100% + 1rem);
        margin-left: -0.5rem;
    }
}

.ugs-filter-attribute-group {
    width: 100%;
    /*border-top: 0.0625rem solid #d1d8dd;*/
}

.ugs-filter-attribute-group-name {
    width: 100%;
    height: 1.4rem;
    background-color: #ebecf1;
    border-radius: 0.25rem;
    text-align: center;
    margin-top: 1rem;
}

.ugs-filter-attribute-group-name > strong {
    font-weight: 600;
}

.ugs-filter-sidebar .label,
.ugs-filter-sidebar label:not(.form-check-label) {
    display: inline-block;
    width: 100%;
    margin-bottom: 0.25rem;
    font-weight: 600;
}

.ugs-filter-form-control[type="date"],
.ugs-filter-form-control[type="number"]
{
    padding: 0.375rem 0.125rem 0.375rem 0.375rem;
}

.ugs-filter-form-control[type="number"] {
    display: inline-block;
    width: calc(50% - 0.75rem);
    /* half of: 100% minus 0.5rem column padding minus 1rem 'to' */
}

.ugs-filter-form-to-num {
    display: inline-block;
    text-align: center;
    width: 1rem;
}

/* Date field, unlike number field, is full width on small screens */
@media (max-width: 768px) {
    .ugs-filter-form-control[type="date"] {
        display: inline-block;
        width: calc(50% - 0.75rem);
        /* half of: 100% minus 0.5rem column padding minus 1rem 'to' */
    }

    .ugs-filter-form-to-date {
        display: inline-block;
        text-align: center;
        width: 1.0rem;
    }
}

.ugs-filter-field .form-check-input {
    min-width: 14px;
}


.ugs-filter-field .form-check-input[type=radio]:disabled {
    pointer-events: none;
}

.ugs-filter-field .form-check-input[type=radio]:disabled::before {
    background-color: var(--disabled-control-bg);
    box-shadow: inset 0px 1px 7px rgba(0, 0, 0, 0.1);
    border: 0.5px solid var(--gray-300);
}

/**
 * Styles for the footer
 */

.web-footer {
    padding: 0;
}

.web-footer hr {
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
    border-top-color: rgba(255, 255, 255, 0.12);
}

/* Dark footer styling - text to shades of white (and smaller font-size) */
.web-footer p,
.web-footer li,
.web-footer a {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.875rem;
}

.web-footer h3,
.web-footer h4,
.web-footer h5,
.web-footer h6 {
    color: rgba(255, 255, 255, 0.6);
}

/* For email obfuscation in the footer */
.cryptedmail::after {
    content:
        attr(data-name) "@"
        attr(data-domain) "."
        attr(data-tld);
}

/**
 * -- Icon footer - small rounded icons and captions
 */

.icon-footer-container {
    padding-top: 1.5rem;
    padding-bottom: 0.875rem;
}

@media (max-width: 768px) {
    .icon-footer-container {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.icons-footer {
    margin-bottom: 0;
}

.icons-footer > img {
    display: block;
    width: 5.5rem;
    margin-bottom: 0.2rem;
}

.icons-footer > h6 {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    color: rgba(255, 255, 255, 0.8);
}

.icons-footer > p {
    margin-top: 0;
    margin-bottom: 0;
}

.copyright-footer {
    margin-top: 0.625rem;
    min-height: 2em;
}

.contact-icons i {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}

.contact-icons > a {
    text-decoration: none;
}

.footer-margin-tb {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
}

.subscribe-text {
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.875rem;
}

.footer-subscribe-button {
    margin-left: -0.125rem;
}

/**
 * Styling for the item generator pages
 */

.ugs-check-icon {
    display: inline-block;
    border: 2px solid #b1c75a;
    width: 1.2em;
    height: 1.2em;
    text-align: center;
    border-radius: 1em;
    box-shadow: 2px 2px 5px 0 #b1c75a;
    box-sizing: content-box;
    color: #b1c75a;
    font-size: larger;
    font-weight: bold;
    vertical-align: middle;
}

.ugs-check-icon::after {
    content: "✓";
}

.ugs-certificate-entry {
    margin-bottom: 0;
    font-weight: bold;
}

.ugs-certificate-entry > a {
    color: unset;
}

/**
 * Styling for the item group generator pages
 */

.ugs-search-term {
    /* Styling for search term in header */
    font-weight: bold;
}

.ugs-filter-subtitle {
    font-size: 1rem;
    font-weight: 500;
    font-style: italic;
}

.ugs-category-search {
    flex-grow: 1;
    position: relative;
    min-width: 10rem;
    max-width: 25rem;
}

.ugs-category-search > input {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 0.0625rem solid #d1d8dd;
}

.ugs-dropdown {
    /* Dropdown menu for products per page */
    display: inline-block;
    padding-left: 0.375rem;
}

.ugs-dropdown > button {
    /* Reduce font size and padding on dropdown button */
    font-size: 0.875rem;
    padding: 0.25rem 0.625rem;
}

.ugs-page-links-container {
    /* Contains links for different pages of item pagination */
    display: flex;
    align-items: center;
    justify-content: center;
}

.ugs-page-links-container .badge-pill {
    /* Override changes to BS */
    background-color: #eeeeee;
    padding: 0.15rem 0.35rem;
}

.ugs-page-links > a:hover,
.ugs-page-links > a:active,
.ugs-page-links > a:focus {
    /* Override changes to BS */
    color: #36414c;
    background-color: #cccccc;
}

.ugs-page-links {
    /* Links to different pages of item pagination */
    flex: 0 1 auto;
    margin-left: 1rem;
    margin-right: 1rem;
    text-align: center;
    line-height: 1.8rem;
}

.ugs-page-links-prev-next {
    /* Prev/next buttons for item pagination */
    flex: 0 0 auto;
}

.ugs-items {
    /* Container for flex items */
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.625rem;
    margin-right: -0.625rem;
    justify-content: center;
}

.ugs-item {
    /* Link for each item as a flex item */
    /* 'max-height' and 'width' set in template */
    flex: 1 0 auto;
    margin: 0.625rem;
    border: 0.0625rem solid #d1d8dd;
    border-radius: 0.25rem;
    position: relative;
    overflow: hidden;
    justify-content: center;
    color: unset;
}

.ugs-item::after {
    /* Fade-out gradient for overflowing box */
    /* 'top' set in template */
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    pointer-events: none;
    background-image:
        linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0),
            rgba(255, 255, 255, 1) 90%
        );
    width: 100%;
    height: 4rem;
}

.ugs-item:link {
    text-decoration: none;
}

.ugs-item:visited {
    text-decoration: none;
}

.ugs-item.ugs-premium {
    border-color: green;
}

.ugs-item.ugs-spares-repairs {
    border-color: rgba(220, 53, 69, 0.45);
}

.ugs-item > p.ugs-premium,
.ugs-item > p.ugs-spares-repairs {
    position: absolute;
    top: 0;
    padding: 0.125rem 0.25rem 0.25rem 0.25rem;
    background-color: rgba(255, 255, 255, 0.7);
    margin: 0;
    font-weight: 600;
}

.ugs-item > p.ugs-premium {
    color: green;
}

.ugs-item > p.ugs-spares-repairs {
    color: rgba(220, 53, 69, 1);
}

.ugs-item > p.ugs-left {
    transform-origin: top left;
    left: 0;
    transform: rotate(-90deg) translateX(-100%);
}

.ugs-item > p.ugs-right {
    transform-origin: top right;
    right: 0;
    transform: rotate(90deg) translateX(100%);
}

.ugs-item > img {
    /* 'max-width' and 'max-height' set in template */
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.ugs-item > p {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center;
}

.ugs-item-name {
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 0;
}

.ugs-item-code {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: 80%;
}

.ugs-item-description {
    text-align: justify;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-height: 2em;
    font-size: 0.875rem;
}

/**
 * Styling for the item pages
 */

.product-container {
    box-shadow: initial;
}

.ugs-item-header {
    text-transform: uppercase;
    margin-top: 1.25rem;
    margin-bottom: 0.625rem;
    font-size: 1.125rem;
}

/**
 * Styling for the cart
 */

.ugs-disable-card {
    opacity: 50%;
    pointer-events: none;
}

.cart-items .item-title > a {
    color: #192734;
}

.cart-payment-addresses [data-section]:not(.select-mode) .address-card {
    cursor: inherit;
}

.cart-payment-addresses [data-section].select-mode .address-card.active {
    border-color: var(--primary);
}

.cart-payment-addresses [data-section].select-mode .address-card {
    border: 1px solid rgba(0, 0, 0, 0.125);
    margin-bottom: 0.5rem;
}

.cart-payment-addresses .address-card .card-body {
    padding: 10px;
}

.cart-payment-addresses [data-section]:not(.select-mode) .address-card {
    height: 100%;
}

.cart-payment-addresses [data-section]:not(.select-mode) .address-card .card-body {
    padding: 0;
}

.cart-payment-addresses .address-card .card-body {
    max-width: inherit;
}

.cart-payment-addresses [data-section].select-mode {
    box-shadow: 0px 1px 2px #ff0000, 0px 0px 4px #ff0000;
}

.cart-payment-addresses [data-section].select-mode .hr,
.cart-payment-addresses [data-section].select-mode .btn-change-address,
.cart-payment-addresses [data-section].select-mode .cart-edit-address,
.cart-payment-addresses [data-section].select-mode .cart-delete-address {
    /* Hide hr, change, edit and delete buttons when selecting options */
    display: none;
}

.cart-payment-addresses [data-section]:not(.select-mode) .select-address-title {
    /* Hide 'Select address' when not select mode */
    display: none;
}

.cart-payment-addresses [data-section]:not(.select-mode) .cart-address-row:not([data-fieldname]) {
    /* If not in select mode, hide non-active address rows */
    display: none;
}

.ugs-cart-shipping-warnings p, .ugs-cart-shipping-warnings ul {
    margin-bottom: 0.25rem;
}

#page-cart .cart-container .cart-payment-addresses hr {
    border-bottom: solid 1px var(--gray-200);
    border-top: none;
    margin-bottom: 0.35rem;
}

#page-cart .cart-container .number-spinner .cart-btn {
    border: 1px solid #ced4da;
}

#page-cart .cart-container .cart-table .remove-cart-item {
    border: 1px solid #ced4da;
}

#btn-apply-coupon-code {
    box-shadow: none;
}

/**
 * -- Item specification table styling
 */

.item-website-specification .table :not(.ugs-item-details-table) {
    width: initial;
}

.item-website-specification .table.ugs-item-details-table {
    width: 100%;
}

.item-website-specification .table {
    max-width: 50rem;
}

.item-website-specification .table:not(.ugs-item-details-table) td {
    border-top: 1px solid #d1d8dd !important;
}

.item-website-specification td {
    font-size: 0.875rem;
}

.ugs-ws-table td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.ugs-ws-table td:first-child {
    width: 30%;
}

/**
 * -- Slideshow styling
 */

.ugs.carousel-inner {
    cursor: zoom-in;
}

@media (min-width: 616px) {
    .slide-image {
        /* Override default of 'relative' since we add a 3:4 padding block */
        position: absolute;
        max-height: 100%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        /* crop image to fit */
        -o-object-fit: contain;
           object-fit: contain;
    }

    .carousel-item > div::after {
        /* set 4:3 aspect ratio */
        content: '';
        display: block;
        padding-bottom: 75%;
        height: 0;
        background: black;
    }
}

.ugs.carousel-control-prev,
.ugs.carousel-control-next {
    /* Trim the carousel bar width */
    width: 10%;
}

.ugs.carousel-control-prev {
    /* Gradient bars */
    opacity: 1;
    background-image:
        linear-gradient(
            to right,
            rgba(0, 0, 0, 0.25) 0%,
            rgba(0, 0, 0, 0.0001) 100%
        );
}

.ugs.carousel-control-next {
    /* Gradient bars */
    opacity: 1;
    background-image:
        linear-gradient(
            to right,
            rgba(0, 0, 0, 0.0001) 0%,
            rgba(0, 0, 0, 0.25) 100%
        );
}

@media (min-width: 992px) {
    .ugs.carousel-control-prev,
    .ugs.carousel-control-next {
        /* Left-right arrow controls initially invisible on larger screens */
        opacity: 0;
        transition: 0.3s;
        font-size: 2rem;
        text-shadow: 0.0625rem 0.125rem 0.25rem rgba(0, 0, 0, 0.8);
    }
}

.ugs.carousel:hover .carousel-control-prev,
.ugs.carousel:hover .carousel-control-next {
    /* Display gradient bars and arrows ONLY on hover */
    opacity: 1;
}

.ugs.carousel-caption {
    /* Stop the caption interfering with the mouseover zoom */
    pointer-events: none;
}

@media (min-width: 992px) {
    .ugs.carousel-indicators {
        /* Only display indicators on small screens */
        display: none;
    }
}

.ugs-carousel-thumbnails {
    /* A flex container for the thumbnails */
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    cursor: pointer;
    justify-content: center;
    /* correct for thumbnail borders */
    margin: 0.0625rem 0 0 0.0625rem;
}

@media (max-width: 615px) {
    .ugs-carousel-thumbnails {
        /* Only display thumbnails on larger screens */
        display: none;
    }
}

.ugs-carousel-thumbnails > li {
    /* We give the thumbnails a forced white background, then use opacity to set
    * a brightening effect on hover */
    background-color: white;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    width: calc(25% + 0.046875rem);  /* 0.75 px */
    position: relative;
    border: 0.0625rem solid black;
    /* collapse the borders */
    padding-left: 0.0625rem;
    padding-top: 0.0625rem;
    margin-left: -0.0625rem;
    margin-top: -0.0625rem;
}

.ugs-carousel-thumbnails > li.active {
    border: 0.125rem solid black;
}

.ugs-carousel-thumbnails > li > img:hover {
    opacity: 0.3;
}

.ugs-carousel-thumbnails > li::after {
    /* set 4:3 aspect ratio */
    content: '';
    display: block;
    padding-bottom: 75%;
    height: 0;
}

.ugs-carousel-thumbnails img {
    /* reposition image inside aspect ratio box */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* crop image to fit */
    -o-object-fit: cover;
       object-fit: cover;
}

.ugs-carousel-thumbnails > li.active img {
    /* Active thumbnail */
    cursor: initial;
    opacity: 0.3;
}

/* Media queries to change number of thumbnails per line */
@media (min-width: 800px) {
    .ugs-carousel-thumbnails > li {
        width: calc(20% + 0.8px);
    }
}

@media (min-width: 900px) {
    .ugs-carousel-thumbnails > li {
        width: calc(16.6666% + 0.83333px);
    }
}

@media (min-width: 992px) {
    .ugs-carousel-thumbnails > li {
        width: calc(25% + 0.75px);
    }
}

@media (min-width: 1350px) {
    .ugs-carousel-thumbnails > li {
        width: calc(20% + 0.8px);
    }
}

@media (min-width: 1600px) {
    .ugs-carousel-thumbnails > li {
        width: calc(16.6666% + 0.8333px);
    }
}

@media (min-width: 1900px) {
    .ugs-carousel-thumbnails > li {
        width: calc(14.2857% + 0.8571px);
    }
}

@media (min-width: 2200px) {
    .ugs-carousel-thumbnails > li {
        width: calc(12.5% + 0.875px);
    }
}

@media (min-width: 2600px) {
    .ugs-carousel-thumbnails > li {
        width: calc(11.1111% + 0.8888px);
    }
}

/**
 * -- Checkout styling
 */

.checkout-success-card {
    margin-top: 2rem;
    min-height: 34vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
